<!--跳转页，或者是中转页面，可以做一个LOADING的效果，这个页面-->
<template>
  <div></div>
</template>

<script>
import { GetUrlParams, isEmptyObj } from '@/utils/index'
import { Toast } from 'vant'
import { Jump } from '@/utils/routh'
export default {
  created () {
    // https:///#/middle?type=meiye&cctl=nocache&appId=MEILI&uId=323198983456555008&cellPhone=18969949739&sign=3fdb0385c0c592cf34c71b8a9911149c
    const urlParams = GetUrlParams()
    //  console.log(urlParams,'urlParams')
    if (!isEmptyObj(urlParams) && urlParams.cellPhone) {
      console.log(urlParams.cellPhone,'phone')
      localStorage.setItem('cellPhone', urlParams.cellPhone)
      this.login(urlParams.cellPhone)
    } else {
      Toast.fail('用户参数异常')
    }
  },
  methods: {
    async login (phone) {
      const res = await this.$store.dispatch('user/login', { phone });
      // console.log(res,'8888888');
      if (!res.success){
        Toast.fail('获取手机号失败');
        return
      }else{
        localStorage.setItem('stock',res.result.state);
        localStorage.setItem('userId',res.result.userId);
      }
      const newUrl = process.env.VUE_APP_ROUTE
      console.log(newUrl,'nenneenge');
      window.location.replace(newUrl) //这里是为了不影响路由的跳转uni-app的页面返回，如果用下面的方法，无法返回暂时先这样
      // Jump('uni:/')
      
    }
  }
}
</script>

<style>
</style>
